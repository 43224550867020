import React from 'react';
import slugify from 'slugify';
import { useScrollTo } from '../../hooks/use-scroll-to';
import Button from '../atoms/Button';
import SimpleHeroSection from '../molecules/SimpleHeroSection';

interface FaqHeroSectionProps {
  heading;
  body;
  topics;
}

const FaqHeroSection = ({ heading, body, topics }: FaqHeroSectionProps) => {
  const { scrollTo } = useScrollTo();

  return (
    <SimpleHeroSection>
      <div className="container">
        <div className="text-center text-navy-blue">
          <h1 className="mb-4 u-h2">{heading}</h1>
          {body && (
            <div
              className="max-w-2xl mx-auto mb-8 u-banner-prose"
              dangerouslySetInnerHTML={{ __html: body }}
            />
          )}
        </div>

        <div className="flex flex-col items-center max-w-4xl mx-auto">
          <div className="flex flex-wrap justify-center flex-grow">
            {topics.map((topic) => (
              <div key={slugify(topic)} className="py-4 mx-4">
                <Button
                  variant="pink"
                  onClick={() => scrollTo(slugify(topic))}
                  className="focus-visible:ring-2 focus-visible:ring-navy-blue focus-visible:ring-opacity-50 focus-visible:ring-offset-2"
                >
                  {topic}
                </Button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </SimpleHeroSection>
  );
};

export default FaqHeroSection;
